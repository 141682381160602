import {
	Button,
	Errors,
	Heading,
	Input,
	Label,
	TextField,
	Container,
	Breadcrumbs,
	BreadcrumbItem,
	Section,
	Page,
} from '@troon/ui';
import { Formspree, formspreeAction } from '@troon/forms';
import { Match, Switch } from 'solid-js';
import { useLocation, useSubmission } from '@solidjs/router';
import { Icon } from '@troon/icons';
import { Title } from '@solidjs/meta';
import { getConfigValue } from '../../../modules/config';
import { useUser } from '../../../providers/user';
import { Grid, GridFive, GridSeven } from '../../../components/layouts/grid';
import type { RouteDefinition } from '@solidjs/router';

export default function TJCContactPage() {
	const submission = useSubmission(action);
	const user = useUser();
	const loc = useLocation();

	return (
		<>
			<Container>
				<Title>Troon Junior Club Contact Form | Play Troon Programs | Troon</Title>

				<div class="pt-4">
					<Breadcrumbs>
						<BreadcrumbItem href="/play-troon-programs">Play Troon Programs</BreadcrumbItem>
						<BreadcrumbItem href="/play-troon-programs/troon-junior-club">Troon Junior Club</BreadcrumbItem>
						<BreadcrumbItem href={loc.pathname}>Contact</BreadcrumbItem>
					</Breadcrumbs>
				</div>

				<Page>
					<Grid>
						<GridFive>
							<Section>
								<Heading as="h2">Troon Junior Club Contact</Heading>
								<ul>
									<li>Email: tjc@troon.com</li>
									<li>Phone: 480.477.0482</li>
									<li>Fax: 480.477.0588</li>
								</ul>
								<Heading as="h3" size="h5">
									Mailing Address:
								</Heading>
								<p>
									15044 N. Scottsdale Rd. Suite 300
									<br />
									Scottsdale, AZ 85254
								</p>
							</Section>
						</GridFive>

						<GridSeven class="rounded border border-neutral bg-neutral-100 p-4 md:p-8">
							<Switch>
								<Match when={submission.result}>
									<div class="container mx-auto flex max-w-xl flex-col items-center gap-6 text-center">
										<Icon name="circle-check" class="text-8xl text-brand" />
										<Heading as="h1" size="h2">
											Request received!
										</Heading>
										<p class="text-lg">
											Thank you for submitting your request. We have received your Troon Privé member request to reserve
											a tee time at one of our participating private clubs. Your desired destination club will be in
											touch to confirm your reservation.
										</p>
									</div>
								</Match>
								<Match when>
									<Formspree action={action} class="flex flex-col gap-6">
										<div class="flex w-full flex-wrap gap-6">
											<TextField name="First name" required class="grow">
												<Label>First name</Label>
												<Input autocomplete="given-name" value={user()?.me.firstName} />
											</TextField>

											<TextField name="Last name" required class="grow">
												<Label>Last name</Label>
												<Input autocomplete="family-name" value={user()?.me.lastName} />
											</TextField>
										</div>

										<TextField name="email" required>
											<Label>Email address</Label>
											<Input autocomplete="email" inputMode="email" value={user()?.me.email} />
										</TextField>

										<TextField name="Comments" required>
											<Label>Comments</Label>
											<Input multiline />
										</TextField>

										<Errors />

										<Button class="w-fit" type="submit">
											Submit
										</Button>
									</Formspree>
								</Match>
							</Switch>
						</GridSeven>
					</Grid>
				</Page>
			</Container>
		</>
	);
}

const action = formspreeAction(getConfigValue('FORMSPREE_PROJECT_ID'), 'troon-junior-club');

export const route = { info: { hasHero: false } } satisfies RouteDefinition;
